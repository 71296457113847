<template>
  <a-drawer
      title="记录"
      width="520"
      :closable="false"
      :visible="visible"
      @close="onClose"
  >
    <span class="text">维护记录</span>
    <div v-for="it in list" v-if="list.length>0">
      <div>{{it.describeRecord}}</div>
<!--      <div>{{it.status}}</div>-->
      <div>{{it.createTime}}</div>
      <a-divider/>
    </div>
    <div v-if="list.length==0">暂无记录</div>
    <span class="text">报价记录</span>
    <div v-for="it in record" v-if="record.length>0">
      <div v-for="obj in it.templateDetail">
        <div>电梯类型：{{obj.elevatorType}}</div>
        <div>规格：{{obj.specification}}</div>
        <div>功能选配：{{obj.function}}</div>
        <div>装潢选配：{{obj.decorate}}</div>
        <div>数量：{{obj.number}}</div>
        <div>基础价格：{{obj.price}}</div>
        <div>装潢总价：{{obj.decorateSubtotal}}</div>
        <div>功能总价：{{obj.functionSubtotal}}</div>
        <div>小计：{{obj.elevatorSubtotal}}</div>
        <a-divider/>
      </div>
      <div>{{it.createTime}}</div>
      <a-divider/>
    </div>
    <div v-if="list.length==0">暂无记录</div>
    <div
        :style="{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          padding: '10px 16px',
          textAlign: 'right',
          left: 0,
          background: '#fff',
          borderRadius: '0 0 4px 4px',
        }"
    >
      <a-button style="marginRight: 8px" @click="onClose">
        取消
      </a-button>
    </div>
  </a-drawer>
</template>

<script>
import {getrecord} from '@/api/report'
export default {
  props:['visible','id'],
  data(){
    return {
      form: this.$form.createForm(this),
      formItemLayout: {
        labelCol: {
          span: 6
        },
        wrapperCol: {
          span: 18
        }
      },
      confirmLoading: false,
      record:[],
      list:[]
    }
  },
  computed: {
    show: {
      get: function (){
        return this.visible;
      },
      set: function () {}
    }
  },
  watch:{
    visible(val){
      if(val) {
       this.load()
      }
    }
  },
  created() {
  },
  methods:{
    load() {
      if(this.id){
        const params = {
          demandSideId:this.id
        }
        getrecord(params).then((res) => {
          console.log(res)
          this.list = res.demandSideRecord
          this.record = res.elevatorRecord
        })
      }
    },
    onClose() {
      this.$emit('close')
    }
  }
}
</script>

<style scoped>
.text{
  font-weight: 500;
  font-size: 18px;
}
</style>