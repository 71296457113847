<template>
  <a-modal
      title="维护"
      :visible="visible"
      width="30%"
      :confirm-loading="confirmLoading"
      ok-text="确认"
      cancel-text="取消"
      @ok="handleConfirm"
      @cancel="handleCancel">
    <a-form :form="form">
      <a-form-item label="项目状态" v-bind="formItemLayout">
        <a-radio-group v-decorator="['status',{initialValue:0}]">
          <a-radio :value="0">
            维护中
          </a-radio>
          <a-radio :value="1">
            成单
          </a-radio>
          <a-radio :value="2">
            报废
          </a-radio>
        </a-radio-group>
      </a-form-item>
      <a-form-item label="项目维护记录" v-bind="formItemLayout">
        <a-textarea
            v-decorator="['describeRecord', {rules: [{ required: true, message: '请输入项目维护记录'}]}]"
            placeholder="请输入项目维护记录"
            :auto-size="{ minRows: 3, maxRows: 5 }"
        />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import {record} from '@/api/report'
export default {
  props:['visible'],
  data(){
    return {
      form: this.$form.createForm(this),
      formItemLayout: {
        labelCol: {
          span: 6
        },
        wrapperCol: {
          span: 18
        }
      },
      maxLength:1000,
      confirmLoading: false,
    }
  },
  computed: {
    show: {
      get: function (){
        return this.visible;
      },
      set: function () {}
    }
  },
  watch:{
    visible(val){
      if(val) {
      }
    }
  },
  methods:{
    setFormValues(record) {
      console.log(record)
      this.demandSideId = record.id
    },
    handleConfirm(e) {
      this.confirmLoading = true;
      this.form.validateFields(async (err, values) => {
        this.confirmLoading = false;
        if(!err) {
          if(this.demandSideId){
            values.demandSideId = this.demandSideId
            record(values).then((res) => {
              this.form.resetFields()
              this.$message.success('操作成功');
              this.$emit('confirm')
              this.$emit('close')
            })
          }
        }
      });
    },
    handleCancel() {
      this.$emit('close')
      this.id=''
      this.form.resetFields()
    }
  }
}
</script>

<style scoped>

</style>