<template>
    <a-drawer
        :title="id?'修改报备信息':'项目报备'"
        :visible="visible"
        width="30%"
        :confirm-loading="confirmLoading"
        @close="handleCancel">
        <a-form :form="form">
            <a-form-item label="需求方" v-bind="formItemLayout" v-if="!id">
                <a-input
                        placeholder="需求方"
                        v-decorator="['demandSideName',
            {rules: [{ required: true,max:20, message: '需求方必填、最大长度50个字符'}]}]"
                />
            </a-form-item>
            <a-form-item label="项目名称" v-bind="formItemLayout" v-if="!id">
                <a-input
                        placeholder="项目名称"
                        v-decorator="['projectName',
            {rules: [{ required: true,max:20, message: '项目名称必填、最大长度50个字符'}]}]"
                />
            </a-form-item>
            <a-form-item label="销售类型" v-bind="formItemLayout" v-if="!id">
                <a-select v-decorator="['saleType',{rules: [{ required: true, message: '必填' }]}]"
                          placeholder="请选择销售类型">
                    <a-select-option v-for="item in types" :key="item.key"
                                     :value="item.key">{{item.name}}
                    </a-select-option>
                </a-select>
            </a-form-item>
            <a-form-item label="项目地址" v-bind="formItemLayout" v-if="!id">
                <a-cascader v-decorator="['projectAddress',{rules: [{ required: true, message: '项目地址必填' }]}]"
                            :options="options" changeOnSelect placeholder="请选择"/>
            </a-form-item>
            <a-form-item label="详细地址" v-bind="formItemLayout" v-if="!id">
                <a-input v-decorator="['detailedAddress',{rules: [{ required: true, message: '必填' }]}]"
                         placeholder="请填写详细地址"/>
            </a-form-item>
            <a-form-item label="项目经纬度" v-bind="formItemLayout" v-if="!id">
                <a-input
                        placeholder="经纬度"
                        v-decorator="['coordinates',
            {rules: [{ required: true, message: '项目纬度'}]}]"
                />
<!--              <a-button @click="set" type="primary">经纬度拾取</a-button>-->
             <div><a href="http://api.map.baidu.com/lbsapi/getpoint/index.html" target="_blank">经纬度拾取</a></div>
            </a-form-item>
            <a-form-item label="需求方联系人" v-bind="formItemLayout">
                <a-input
                        placeholder="需求方联系人"
                        v-decorator="['demandSideUser',{rules: [{ required: true,max:20, message: '最大长度20个字符'}]}]"
                />
            </a-form-item>
            <a-form-item label="需求方联系电话" v-bind="formItemLayout">
                <a-input
                        placeholder="需求方联系电话"
                        v-decorator="['demandSidePhone',{rules: [{ required: true,max:11, message: '最大长度11个字符'}]}]"
                />
            </a-form-item>
            <a-form-item label="项目目前进展" v-bind="formItemLayout">
                <a-textarea :maxLength="maxLength" v-decorator="['projectProgress',{rules: [{ required: true,max:200, message: '项目目前进展必填' }]}]" />
            </a-form-item>
          <a-form-item label="电梯数量" v-bind="formItemLayout" v-if="!id">
            <span class="text">
               <a-checkbox v-decorator="['type1',{valuePropName:'checked',initialValue:false}]">客梯</a-checkbox>
              <a-input-number v-decorator="['number1']" />
            </span>
            <span class="text">
               <a-checkbox v-decorator="['type2',{valuePropName:'checked',initialValue:false}]">货梯</a-checkbox>
              <a-input-number v-decorator="['number2']" />
            </span>
            <span class="text">
               <a-checkbox v-decorator="['type3',{valuePropName:'checked',initialValue:false}]">扶梯</a-checkbox>
              <a-input-number v-decorator="['number3']" />
            </span>
            <span class="text">
               <a-checkbox v-decorator="['type4',{valuePropName:'checked',initialValue:false}]">其他</a-checkbox>
              <a-input-number v-decorator="['number4']" />
            </span>
          </a-form-item>
            <a-form-item label="项目规模" v-bind="formItemLayout">
                <a-textarea :maxLength="maxLength" v-decorator="['occupation',{rules: [{ required: true,max:200, message: '项目规模必填' }]}]" />
            </a-form-item>
        </a-form>
      <div
          :style="{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          padding: '10px 16px',
          textAlign: 'right',
          left: 0,
          background: '#fff',
          borderRadius: '0 0 4px 4px',
        }"
      >
        <a-button style="marginRight: 8px" type="primary" @click="handleConfirm">
          确定
        </a-button>
        <a-button style="marginRight: 8px" @click="handleCancel">
          取消
        </a-button>
      </div>
      <map-modal
          ref="mapModal"
          :visible="mapVisible"
          @Mclose="mapCanle"
          @Mconfirm="mapConfirm"
      />
    </a-drawer>
</template>

<script>
    import {add,update} from '@/api/report'
    import mapModal from "@/views/report/module/mapModal";
    import addressJsom from "./pca"
    export default {
      components:{mapModal},
      props:['visible'],
       data(){
           return {
             type1: false,
               mapVisible: false,
               form: this.$form.createForm(this),
               formItemLayout: {
                   labelCol: {
                       span: 6
                   },
                   wrapperCol: {
                       span: 18
                   }
               },
               maxLength:1000,
               confirmLoading: false,
               rules:{},
               types:[
                   {key:0,name:'直销'},
                   {key:1,name:'代理商'}
               ],
               optionsNow:[],
               options:[],
               id:''
           }
       },
        computed: {
            show: {
                get: function (){
                    return this.visible;
                },
                set: function () {}
            }
        },
        watch:{
            visible(val){
                if(val) {
                  console.log(this.form.getFieldValue('type1'))
                }
            }
        },
        created() {
            const optionsNow = this.optionsNow
            Object.keys(addressJsom).forEach(function(v,k){

                optionsNow.push({})
                optionsNow[k].value = v
                optionsNow[k].label = v


                optionsNow[k].children = []

                Object.keys(addressJsom[v]).forEach(function(v2,k2) {

                    optionsNow[k].children.push({})
                    optionsNow[k].children[k2].value = v2
                    optionsNow[k].children[k2].label = v2


                    optionsNow[k].children[k2].children = []


                    for(let i in addressJsom[v][v2]) {

                        optionsNow[k].children[k2].children.push({})

                        optionsNow[k].children[k2].children[i].value = addressJsom[v][v2][i]
                        optionsNow[k].children[k2].children[i].label = addressJsom[v][v2][i]

                    }
                })
            })
            this.options = this.optionsNow
        },
        methods:{
          set() {
            this.mapVisible = true
          },
            setFormValues(record) {
              Object.keys(record).forEach(key => {
                this.form.getFieldDecorator(key);
                let obj = {};
                if(key == "id") {
                  this.id = record[key]
                }else {
                 this.$nextTick(()=>{
                   obj[key] = record[key];
                   this.form.setFieldsValue(obj);
                 })
                }
              });
            },
            handleConfirm(e) {
                this.confirmLoading = true;
                this.form.validateFields(async (err, values) => {
                    this.confirmLoading = false;
                    if(!err) {
                        if (!this.id) {
                          console.log(values)
                          values.projectAddress = values.projectAddress.join('/')
                          const quantitys = []
                          if(values.number1&&values.type1) {
                            quantitys.push({
                              quantity:values.number1,
                              elevatorType: 0
                            })
                          }
                          if(values.number2&&values.type2) {
                            quantitys.push({
                              quantity:values.number2,
                              elevatorType: 1
                            })
                          }
                          if(values.number3&&values.type3) {
                            quantitys.push({
                              quantity:values.number3,
                              elevatorType: 2
                            })
                          }
                          if(values.number4&&values.type4) {
                            quantitys.push({
                              quantity:values.number4,
                              elevatorType: 3
                            })
                          }
                          values.quantitys= quantitys
                          delete values.number1
                          delete values.number2
                          delete values.number3
                          delete values.number4
                          delete values.coord
                          delete values.inate
                          if(values.quantitys.length==0) {
                            this.$message.error('电梯数量不能为空！');
                            return
                          }
                          add(values).then((res) => {
                            this.form.setFieldsValue({
                              "type1":false,
                              "type2":false,
                              "type3":false,
                              "type4":false
                            })
                                this.form.resetFields()
                                this.$message.success('操作成功');
                                this.$emit('confirm')
                                this.$emit('close')
                            })
                        }else {
                            values.id = this.id
                          delete values.projectAddress
                          delete values.demandSideName
                          delete values.projectName
                          delete values.saleType
                          delete values.coordinates
                          delete values.detailedAddress
                            update(values).then((res) => {
                                this.id = ''
                              this.form.setFieldsValue({
                                "type1":false,
                                "type2":false,
                                "type3":false,
                                "type4":false
                              })
                                this.form.resetFields()
                                this.$message.success('操作成功');
                                this.$emit('confirm')
                                this.$emit('close')
                            })
                        }
                    }
                });
            },
            handleCancel() {
              this.form.setFieldsValue({
                "type1":false,
                "type2":false,
                "type3":false,
                "type4":false
              })
                this.$emit('close')
                this.id = ''
                this.form.resetFields()
            },
          mapCanle() {
            this.mapVisible = false
          },
          mapConfirm(e) {
            console.log(e)
            this.form.setFieldsValue({
              coord:e[0],
              inate:e[1]
            });
            this.mapVisible = false
          },
        }
    }
</script>

<style scoped>
.text{padding: 0 20px 0 0}
</style>