const columns = [
        {
            title: '#',
            scopedSlots: { customRender: 'serial' },
            fixed: 'left',
            width: 60,
        },
        {
            title: '报备人',
            fixed: 'left',
            dataIndex: 'createName',
            key: 'createName',
            width: 110,
        },
        {
            title: '备案号',
            dataIndex: 'demandSideNumber',
            key: 'demandSideNumber',
            width: 160,
            ellipsis: true,
            scopedSlots: { customRender: 'demandSideNumber' },
        },
        {
            title: '需求方',
            dataIndex: 'demandSideName',
            key: 'demandSideName',
            ellipsis: true,
            width: 200
        },
        {
            title: '项目名称',
            dataIndex: 'projectName',
            key: 'projectName',
            ellipsis: true,
            width: 200,
            scopedSlots: { customRender: 'projectName' },
        },
        {
            title: '销售类型',
            dataIndex: 'saleType',
            width: 120,
            key: 'saleType',
            customRender: (text, row, index) => {
                return text ==0? '直销' : '代理商';
            },
        },
        {
            title: '经纬度',
            key: 'coordinates',
            dataIndex: 'coordinates',
            width: 180,
            ellipsis: true,
        },
        {
            title: '项目地址',
            dataIndex: 'projectAddress',
            key: 'projectAddress',
            width: 220,
            ellipsis: true,
            scopedSlots: { customRender: 'projectAddress' },
        },
        {
            title: '详细地址',
            dataIndex: 'detailedAddress',
            key: 'detailedAddress',
            ellipsis: true,
            width: 220,
            scopedSlots: { customRender: 'detailedAddress' },
        },
        {
            title: '项目规模',
            dataIndex: 'occupation',
            key: 'occupation',
            ellipsis: true,
            width: 320,
            scopedSlots: { customRender: 'occupation' },
        },
        {
            title: '项目状态',
            dataIndex: 'status',
            key: 'status',
            ellipsis: true,
            width: 120,
            scopedSlots: { customRender: 'status' },
        },
        {
            title: '需求方联系人',
            dataIndex: 'demandSideUser',
            key: 'demandSideUser',
            width: 120,
        },
        {
            title: '需求方电话',
            dataIndex: 'demandSidePhone',
            key: 'demandSidePhone',
            width: 130,
        },
        {
            title: '电梯数量',
            dataIndex: 'quantitys',
            key: 'quantitys',
            width: 100,
            scopedSlots: { customRender: 'quantitys' },
        },
        {
            title: '创建时间',
            dataIndex: 'createTime',
            key: 'createTime',
            width: 200,
        },
        {
            title: '维护记录',
            dataIndex: 'record',
            key: 'record',
            fixed: 'right',
            width: 100,
            scopedSlots: { customRender: 'record' },
        },
        {
            title: '操作',
            key: 'operation',
            fixed: 'right',
            width: 140,
            scopedSlots: { customRender: 'action' },
        },
    ];
export {
    columns
}
