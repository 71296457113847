<template>
  <div class="container">
    <a-row>
      <a-form :layout="formLayout">
        <a-form-item
            label="组织机构"
            v-if="userInfo.isMain"
        >
          <a-tree-select style="width: 300px"
                         @select="parentChange"
                         :treeData="types"
                         allowClear
                         v-model="searchForm.departmentSn"
                         placeholder='请选择组织机构'
                         treeDefaultExpandAll>
          </a-tree-select>
          <a-checkbox v-model="searchForm.isLower" style="padding-left: 15px">
            含下级
          </a-checkbox>
        </a-form-item>
        <a-form-item
            label="项目名称"
        >
          <a-input placeholder="项目名称" v-model="searchForm.projectName"/>
        </a-form-item>
        <a-form-item
            label="报备人"
        >
          <a-input placeholder="报备人" v-model="searchForm.createName"/>
        </a-form-item>
        <a-form-item
            label="报备时间"
        >
          <a-range-picker v-model="searchForm.Date" format="YYYY-MM-DD" :placeholder="['开始时间', '结束时间']" :locale="locale"/>
        </a-form-item>
        <a-form-item>
          <a-button type="primary" @click="search">查询</a-button>
          <a-button style="margin-left: 20px" @click="reset()">重置</a-button>
        </a-form-item>
      </a-form>
    </a-row>
    <a-row style="padding: 12px 0 0 12px">
      <a-button type="primary" @click="addOrEdite(null)">新增</a-button>
    </a-row>
    <br/>
    <a-table
        :columns="columns"
        :dataSource="dataList"
        rowKey="id"
        bordered
        :scroll="{ x: 900,y:400 }"
        :loading="loading"
        :pagination="false">
             <span slot="serial" slot-scope="text, record, index">
                {{ index + 1 }}
            </span>
            <span slot="status" slot-scope="text,record">
               <a-tag color="#108ee9" v-if="text==0">维护中</a-tag>
               <a-tag color="#2db7f5" v-if="text==1">成单</a-tag>
               <a-tag color="#f50" v-if="text==2">终止报废</a-tag>
               <a-tag color="#f50" v-if="text==3">延期报废</a-tag>
             </span>
             <span slot="quantitys" slot-scope="text,record">
               <span v-if="text.length>0">{{text.map((item)=>{return item.quantity}).reduce((x, y) => x + y)}}</span>
             </span>
             <span slot="demandSideNumber" slot-scope="text, record">
                <a @click="Clone(text, record)">
                  <a-icon type="copy" />
                </a>
              {{ text }}
            </span>
             <span slot="projectName" slot-scope="text, record">
                <a @click="Clone(text, record)">
                  <a-icon type="copy" />
                </a>
              {{ text }}
            </span>
             <span slot="projectAddress" slot-scope="text, record">
                <a @click="Clone(text, record)">
                  <a-icon type="copy" />
                </a>
              {{ text }}
            </span>
             <span slot="detailedAddress" slot-scope="text, record">
                <a @click="Clone(text, record)">
                  <a-icon type="copy" />
                </a>
              {{ text }}
            </span>
            <span slot="occupation" slot-scope="text, record">
                <a @click="Clone(text, record)">
                  <a-icon type="copy" />
                </a>
              {{ text }}
            </span>
             <span slot="record" slot-scope="text,record">
               <a class="txt-danger" href="javascript:;" @click="saw(record)">查看</a>
             </span>
             <span slot="action" slot-scope="text">
                <a @click="addOrEdite(text)" v-if="text.status==0&&text.createId==userInfo.id">编辑</a>
                <a-divider type="vertical" v-if="text.status==0&&text.createId==userInfo.id"/>
              <a @click="defend(text)" v-if="text.status==0&&text.createId==userInfo.id">维护</a>
                <a-divider type="vertical" v-if="userInfo.classification==0&&text.status==3&userInfo.id!==text.createId"/>
              <a-popconfirm
                  title="是否同意激活？"
                  @confirm="vate(text.id)"
                  okText="是"
                  cancelText="否"
                  placement="topRight"
              >
                 <a class="txt-danger" href="javascript:;" v-if="userInfo.classification==0&&text.status==3&userInfo.id!==text.createId">激活</a>
                </a-popconfirm>

            </span>
    </a-table>
    <br/>
    <Pagination
        :total="pagination.total"
        :pageSize="pagination.size"
        :current="pagination.current"
        @change="onPageChange"
        @showSizeChange="onPageSizeChange"
    />
    <form-modal
        ref="FormModal"
        :visible="visible"
        @close="handcanle"
        @confirm="handleConfirm"
    />
    <defend-modal
        ref="defendModal"
        :visible="defendVisible"
        @close="defendCanle"
        @confirm="defendConfirm"
    />
    <info-modal
        ref="sawModal"
        :id="demandSideId"
        :visible="sawVisible"
        @close="sawCanle"
    />
  </div>
</template>

<script>
import {columns} from "./module";
import FormModal from "./module/formModal"
import InfoModal from "./module/infoModal";
import {list, type, active} from '@/api/report'
import Pagination from "../../components/Pagination";
import moment from 'moment';
import DefendModal from "@/views/report/module/defendModal";
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';
import copy from '@/utils/request'
export default {
  components: {DefendModal, FormModal, Pagination, InfoModal},
  data() {
    return {
      locale,
      moment,
      visible: false,
      sawVisible: false,
      columns,
      formLayout: 'inline',
      defendVisible: false,
      loading: false,
      searchForm: {},
      types: [],
      pagination: {
        current: 1,
        size: 10
      },
      dataList: [],
      userInfo: {},
      demandSideId: ''
    }
  },
  created() {
    this.loadType()
    this.loadList()
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
  },
  methods: {
    loadType() {
      type(this.searchForm).then((res) => {
        var departmentSn = JSON.parse(localStorage.getItem('userInfo')).departmentSn
        var departmentName = JSON.parse(localStorage.getItem('userInfo')).departmentName
        if (res.length > 0) {
          this.types = [
            {
              key: departmentSn,
              value: departmentSn,
              title: departmentName,
              children: this.convertToTreeData(res, departmentSn)
            }
          ];
        }
      })
    },
    convertToTreeData(data, pid) {
      const result = [];
      let temp = [];
      for (let i = 0; i < data.length; i++) {
        if (data[i].parentSn == pid) {
          const obj = {
            key: data[i].departmentSn,
            value: data[i].departmentSn,
            title: data[i].departmentName
          };
          temp = this.convertToTreeData(data, data[i].departmentSn);
          if (temp.length > 0) {
            obj["children"] = temp;
          }
          result.push(obj);
        }
      }
      return result;
    },
    parentChange(value, label, extra) {
      this.searchForm.departmentSn = extra.node.dataRef.key
      this.loadList()
    },
    search() {
      if (this.searchForm.Date) {
        this.searchForm.startTime = this.searchForm.Date[0].format('YYYY-MM-DD')
        this.searchForm.endTime = this.searchForm.Date[1].format('YYYY-MM-DD')
      }
      this.loadList()
    },
    reset() {
      this.searchForm = {}
      this.loadList()
    },
    loadList() {
      this.loading = true
      const params = {
        ...this.searchForm,
        current: this.pagination.current,
        size: this.pagination.size
      }
      list(params).then((res) => {
        this.loading = false
        this.dataList = res.records
        const pagination = {...this.pagination}
        pagination.total = Number(res.total)
        pagination.pageSize = Number(10)
        this.pagination = pagination
      })
    },
    onPageChange(current, pageSize) {
      this.pagination.current = current
      this.pagination.size = pageSize
      this.loadList()
    },
    onPageSizeChange(current, pageSize) {
      this.pagination.current = current
      this.pagination.size = pageSize
      this.loadList()
    },
    addOrEdite(row) {
      this.visible = true
      if (row) {
        this.$refs.FormModal.setFormValues(row)
      }
    },
    handcanle() {
      this.visible = false
    },
    handleConfirm() {
      this.loadList()
    },
    defend(row) {
      this.defendVisible = true
      if (row) {
        this.$refs.defendModal.setFormValues(row)
      }
    },
    saw(row) {
      console.log(row)
      if (row) {
        this.demandSideId = row.id
        this.sawVisible = true
      }
    },
    sawCanle() {
      this.sawVisible = false
    },
    defendCanle() {
      this.defendVisible = false
    },
    defendConfirm() {
      this.loadList()
    },
    vate(id) {
      active({id: id}).then((res) => {
        this.loadList()
      })
    },
    Clone (text) {
      console.log(text)
      this.copy(text).then((res) => {
        console.log(res)
//这里是调用ant的提示框，可以根据自己的需求看写什么
        this.$message.success(res)
      })
    }
  }
}
</script>

<style scoped>
</style>