import {
    axios
} from '@/utils/request'

const list = (parameter) => {
    return axios({
        url: '/demandSide/list',
        method: 'post',
        data: parameter
    })
}
const type = (parameter) => {
    return axios({
        url: '/department/list',
        method: 'post',
        data: parameter
    })
}
const add = (parameter) => {
    return axios({
        url: '/demandSide',
        method: 'post',
        data: parameter
    })
}
const active = (parameter) => {
    return axios({
        url: '/demandSide/activate',
        method: 'post',
        params: parameter
    })
}
const update = (parameter) => {
    return axios({
        url: '/demandSide',
        method: 'PUT',
        data: parameter
    })
}
const del = (parameter) => {
    return axios({
        url: '/department',
        method: 'delete',
        params: parameter
    })
}
const record = (parameter) => {
    return axios({
        url: '/demandSideRecord',
        method: 'post',
        data: parameter
    })
}
const getrecord = (parameter) => {
    return axios({
        url: '/demandSideRecord',
        method: 'get',
        params: parameter
    })
}
export {
    list,
    add,
    update,
    del,
    type,
    active,
    record,
    getrecord
}
